import React from "react";

import "./kāinga.css";

const Kāinga = () => {
  return (
    <div className="kāinga">
      <div className="home-container">
        <div className="pūkare-heading-item">
          <h1>PŪKARE</h1>
        </div>
        <div className="intro-item">
          <p>Use Pūkare to bring Te Reo Māori into your home or workplace.</p>
          <p>
            Select a collection below to open a full-screen, rolling display.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Kāinga;
