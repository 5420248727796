import React from "react";

import KohingaCard from "./kohingaCard";
import tari from "../images/tari.jpg";
import kāinga from "../images/kāinga.jpg";
import kīwaha from "../images/tari2.jpg";

import "./kaupapa.css";

const Kauapapa = () => {
  return (
    <div className="kaupapa">
      <div className="kohinga-heading-container">
        <h1 className="kohinga-mainheading">Kohinga</h1>
        <div className="kohinga-subheading-container">
          <h1 className="kohinga-subheading">Collections</h1>
          <p className="kohinga-instruction">(click or tap one below)</p>
        </div>
      </div>
      <div className="kohinga-container">
        <KohingaCard
          link="/kei-te-tari"
          title="Kei te Tari"
          description="Kupu for the office"
          image={tari}
        />

        <KohingaCard
          link="/kei-te-kainga"
          title="Kei te Kāinga"
          description="Kupu for the home"
          image={kāinga}
        />
        <KohingaCard
          link="/kiwaha"
          title="Kīwaha"
          description="Short phrases for everyday"
          image={kīwaha}
        />
      </div>
    </div>
  );
};

export default Kauapapa;
