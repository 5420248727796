/* eslint-disable */
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NavBar from "../components/navBar";
import Kāinga from "../components/kāinga";
import Kaupapa from "../components/kaupapa";
import Footer from "../components/footer";
import Border from "../components/border";

const IndexPage = () => (
  <Layout>
    <SEO title="Kāinga" />
    <NavBar />
    <Kāinga />
    <Border />
    <Kaupapa />
    <Border />
    <Footer />
  </Layout>
);

export default IndexPage;
