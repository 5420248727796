import { Link } from "gatsby";
import React from "react";

const kohingaCard = props => {
  return (
    <div className="kohinga-item">
      <Link to={props.link}>
        <div className="kohinga-heading">{props.title}</div>
        <div className="kohinga-description">{props.description}</div>
        <div className="kohinga-image">
          <img className="kohinga-image" src={props.image} alt={props.image} />
        </div>
      </Link>
    </div>
  );
};

export default kohingaCard;
