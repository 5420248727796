import React from "react";
import idia from "../images/idia-logo.svg";

import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="IDIA-logo">
          <a href="https://www.idia.nz/">
            <img src={idia} alt="IDIA" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
