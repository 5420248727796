import React from "react";
import "./border.css";

export default function border() {
  return (
    <div className="border-wrapper">
      <div className="border" />
    </div>
  );
}
